import Farmer from './Farmer';
import React from 'react';
import Salesman from './Salesman';

export default function DataTestimonyHDI({ trial, mainColor }) {
  return (
    <div>
      <div className={`text-4xl MapOverlay-header pb-12 ${mainColor}`}>
        Témoignage d'agriculteurs sur la gamme{' '}
        {trial.data.products[0].category.title} HDi®
      </div>

      <div className="MapOverlay-content">
        <div className="px-6 divide-y">
          {trial.data.products.map((product, index) => (
            <div className="py-12 lg:flex" key={index}>
              <Farmer
                farmer={product.farmer}
                since={
                  product.farmer.year_hdi
                    ? `Fidèle aux HDi® depuis ${product.farmer.year_hdi} ${
                        product.farmer.year_hdi > 1 ? 'ans' : 'an'
                      }`
                    : ''
                }
              />
              <div className="flex-grow max-w-lg lg:ml-11">
                <div className="flex flex-wrap mb-6 lg:flex-nowrap gap-x-12 gap-y-6">
                  {product?.product?.title ? (
                    <div className="text-xs">
                      <div className="mb-1 font-semibold">Variété</div>
                      <div className="text-gray-600">
                        {product.product.title}
                      </div>
                      {product?.product?.inscription_date ? (
                        <div className="text-gray-600">
                          Inscription {product?.product?.inscription_date}
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  {product.feed_type ? (
                    <div className="text-xs">
                      <div className="mb-1 font-semibold">Type de ration</div>
                      <div className="text-gray-600">{product.feed_type}</div>
                    </div>
                  ) : null}

                  {product.herd ? (
                    <div className="text-xs">
                      <div className="mb-1 font-semibold">Infos troupeau</div>
                      <div className="text-gray-600">{product.herd}</div>
                    </div>
                  ) : null}
                </div>
                {product.description ? (
                  <blockquote className="mt-3 text-base text-gray-600">
                    « {product.description} »
                  </blockquote>
                ) : null}
              </div>
            </div>
          ))}
        </div>
        <Salesman salesman={trial.data.salesman} />
      </div>
    </div>
  );
}
