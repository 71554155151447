import React from 'react';

export default function Farmer({ farmer, since }) {
  return (
    <div className="flex-shrink-0 mr-auto">
      <img
        src={farmer.image}
        alt=""
        className="object-cover w-20 h-20 mx-auto lg:mb-2 mb-4 rounded-full"
      />
      <div className="text-center mb-8 lg:mb-0">
        <div className="font-semibold">{farmer.firstname}</div>
        <div className="text-gray-500 text-xxs">{farmer.zipcode}</div>
        <div className="text-gray-500 text-xxs">{farmer.city}</div>
        {since ? <div className="text-gray-500 text-xxs">{since}</div> : null}
      </div>
    </div>
  );
}
