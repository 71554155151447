import React from 'react';

export default function SeasonSwitch({
  seasons = [],
  selected = '',
  handleChange = () => {},
  withDefault
}) {
  return (
    <select
      className="mb-10"
      value={selected}
      onChange={(e) => {
        handleChange(e.target.value);
      }}
    >
      {withDefault ? <option value="">Filtrer par cycle</option> : null}
      {seasons.map((opt) => (
        <option key={opt.value} value={opt.value}>
          {opt.label}
        </option>
      ))}
    </select>
  );
}
