import DataIndependent from './Independent.js';
import DataIntern from './Intern.js';
import DataTestimonyClassic from './TestimonyClassic.js';
import DataTestimonyHDI from './TestimonyHDI.js';
import DataTestimonySilo from './TestimonySilo.js';
import React from 'react';

export default function renderDataComponent(data, mainColor, category) {
  if (!data?.type) return null;

  let retrievedColor = null;

  if (window.TRIALS) {
    retrievedColor = JSON.parse(window.TRIALS).filter(
      (item) => item.id === data.id
    ).color;

    retrievedColor = 'bg-brand-600';
  }

  switch (data.type) {
    case 'intern':
      return (
        <DataIntern
          trial={data}
          mainColor={retrievedColor ? retrievedColor : mainColor}
          category={category}
        />
      );
    case 'testimony_classic':
      return (
        <DataTestimonyClassic
          trial={data}
          mainColor={mainColor}
          category={category}
        />
      );
    case 'testimony_silo_comparison':
      return (
        <DataTestimonySilo
          trial={data}
          mainColor={mainColor}
          category={category}
        />
      );
    case 'testimony_hdi_review':
      return (
        <DataTestimonyHDI
          trial={data}
          mainColor={mainColor}
          category={category}
        />
      );
    case 'independent':
    case 'independent_hdi':
      return (
        <DataIndependent
          data={data}
          mainColor={mainColor}
          category={category}
        />
      );

    default:
      return null;
  }
}
