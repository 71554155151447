import React from 'react';
import Farmer from './Farmer';
import Salesman from './Salesman';
import StarRating from './StarRating';

export default function DataTestimonyClassic({ trial, mainColor }) {
  return (
    <div>
      <div className={`text-4xl MapOverlay-header pb-12 ${mainColor}`}>
        Témoignage d'agriculteurs sur la semence{' '}
        {trial.data.products[0].category.title}{' '}
        {trial.data.products[0].product.title}
      </div>

      <div className="MapOverlay-content">
        <div className="px-6 divide-y">
          {trial.data.products.map((product, index) => (
            <div className="flex py-12" key={index}>
              <Farmer farmer={product.farmer} />
              <div className="flex-grow max-w-lg">
                <div className="flex justify-between mb-6">
                  {product.date_semis ? (
                    <div className="text-xs">
                      <div className="mb-1 font-semibold">Date semis</div>
                      <div className="text-gray-600">{product.date_semis}</div>
                    </div>
                  ) : null}

                  {product.date_recolte ? (
                    <div className="text-xs">
                      <div className="mb-1 font-semibold">Date récolte</div>
                      <div className="text-gray-600">
                        {product.date_recolte}
                      </div>
                    </div>
                  ) : null}

                  {product.efficiency ? (
                    <div className="text-xs">
                      <div className="mb-1 font-semibold">Rendement (q/ha)</div>
                      <div className="text-gray-600">
                        Variété LG : {product.efficiency}
                      </div>
                      <div className="text-gray-600">
                        Moyenne de l'essai : {product.efficiency_mean}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div>
                  {product.vigueur_start ? (
                    <div className="flex items-center">
                      <div className="font-semibold">Vigueur de départ</div>
                      <div className="ml-4">
                        <StarRating mark={product.vigueur_start} />
                      </div>
                    </div>
                  ) : null}
                  {product.vigueur_start_comment ? (
                    <blockquote className="mt-3 text-base text-gray-600">
                      « {product.vigueur_start_comment} »
                    </blockquote>
                  ) : null}
                </div>
                {product.fall_dynamic ? (
                  <div className="mt-4">
                    {product.fall_dynamic ? (
                      <div className="flex items-center">
                        <div className="font-semibold">Dynamique automnale</div>
                        <div className="ml-4">
                          <StarRating mark={product.fall_dynamic} />
                        </div>
                      </div>
                    ) : null}
                    {product.fall_dynamic_comment ? (
                      <blockquote className="mt-3 text-base text-gray-600">
                        « {product.fall_dynamic_comment} »
                      </blockquote>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>

        <Salesman salesman={trial.data.salesman} />
      </div>
    </div>
  );
}
