import React from 'react';
import { useEffect, useRef, useState } from 'react';
import Splide from '@splidejs/splide';
import { ReactComponent as ArrowNext } from '@icons/arrow-next.svg';
import { ReactComponent as ArrowPrev } from '@icons/arrow-previous.svg';

const isOverflown = (element, length, splidesWidth) => {
  if (!element) return;

  const width = element.getBoundingClientRect().width * length;
  return width > splidesWidth;
};

export default function Tabs({
  productsTitles,
  activeProductTitle,
  setActiveProductTitle,
  trialId,
  mainColor
}) {
  const listTabRef = useRef(null);
  const [splide, setSplide] = useState(null);
  const [splideIndex, setSplideIndex] = useState(0);
  const [splideWidth, setSplideWidth] = useState(0);

  useEffect(() => {
    const splidesTabs = document.querySelector(`#trial-${trialId}`);
    const splidesWidth = splidesTabs.getBoundingClientRect().width;

    const CONFIG_SPLIDE = {
      loop: true,
      arrows: isOverflown(
        listTabRef.current.lastElementChild,
        listTabRef.current?.children.length,
        splidesWidth
      ),
      autoWidth: true,
      autoHeight: true,
      pagination: false,
      drag: isOverflown(
        listTabRef.current.lastElementChild,
        listTabRef.current?.children.length,
        splidesWidth
      ),
      perMove: 1,
      padding: {
        right: '1.5rem',
        left: '0rem'
      }
    };

    if (splidesTabs) {
      setSplideWidth(splidesTabs.getBoundingClientRect().width);
      setSplide(new Splide(`#trial-${trialId}`, CONFIG_SPLIDE));
    }
  }, [trialId]);

  useEffect(() => {
    if (splide) {
      splide.mount();

      splide.on('moved', function () {
        // This will be executed.

        setSplideIndex(splide?.index);
      });

      return () => {
        splide.destroy();
      };
    }
  }, [splide]);

  if (!Array.isArray(productsTitles)) return null;

  let color = mainColor.replace('bg-', '');

  if (color.includes('brand')) {
    color = color.slice(0, -4);
  }

  return (
    <>
      <div
        className="TryOutTabs splide js-productSlideTryOut"
        id={`trial-${trialId}`}
      >
        <div className={`splide__arrows`}>
          <div
            className={`arrow-bg--prev ${color} ${
              splideIndex <= 0 && 'invisible'
            }`}
          >
            <button className={`splide__arrow splide__arrow--prev ${color}`}>
              <ArrowPrev className={'w-4 h-4 fill-current transform-none'} />
            </button>
          </div>
          <div
            className={`arrow-bg--next ${color} ${
              listTabRef.current &&
              !isOverflown(
                listTabRef.current?.lastElementChild,
                listTabRef.current?.children.length,
                splideWidth
              ) &&
              'invisible'
            }`}
          >
            <button className={`splide__arrow splide__arrow--next ${color}`}>
              <ArrowNext className={'w-4 h-4 fill-current'} />
            </button>
          </div>
        </div>

        <div className="splide__track">
          <ul className="splide__list" ref={listTabRef}>
            {productsTitles.map((title, index) => (
              <li className="splide__slide" key={title}>
                <button
                  className={`btn tab hover:bg-black hover:bg-opacity-25 ${
                    title === activeProductTitle ? 'bg-black bg-opacity-25' : ''
                  }`}
                  onClick={() => setActiveProductTitle(title)}
                >
                  {title}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
