import React from 'react';
import { ReactComponent as StarIcon } from '@icons/star-filled.svg';

export default function StarRating({ mark }) {
  if (!mark) return null;
  return (
    <div className="flex">
      {Array(5)
        .fill(null)
        .map((_, i) => {
          return i < mark ? (
            <StarIcon key={i} className="w-4 h-4 fill-current text-sunflower" />
          ) : (
            <StarIcon key={i} className="w-4 h-4 text-gray-200 fill-current" />
          );
        })}
    </div>
  );
}
