import React from 'react';

function getVariantStyle(variant) {
  switch (variant) {
    case 'intern':
    case 'testimony_classic':
    case 'testimony_hdi_classic':
    case 'testimony_hdi_review':
      return 'Map-marker--red';
    case 'testimony_':
    case 'testimony_silo_comparison':
      return 'Map-marker--dark';

    default:
      return '';
  }
}

const Marker = (marker) => {
  return (
    <div className={`Map-marker ${getVariantStyle(marker.variant)}`}></div>
  );
};

export default Marker;
