import React from 'react';

export default function MapNav({
  type,
  handleClick,
  hasIndependent,
  hasIntern,
  hasTestimony,
  internLabel = 'Essais LG et Partenaires'
}) {
  return (
    <nav className="flex mb-10 gap-x-4">
      {hasTestimony ? (
        <button
          onClick={() => handleClick('testimony')}
          className={`TabSection ${type === 'testimony' ? 'active' : ''}`}
        >
          Témoignages éleveurs
        </button>
      ) : null}
      {hasIntern ? (
        <button
          onClick={() => handleClick('lg')}
          className={`TabSection ${type === 'lg' ? 'active' : ''}`}
        >
          {internLabel}
        </button>
      ) : null}
      {hasIndependent ? (
        <button
          onClick={() => handleClick('independent')}
          className={`TabSection ${type === 'independent' ? 'active' : ''}`}
        >
          Essais indépendants
        </button>
      ) : null}
    </nav>
  );
}
