import { head } from 'lodash-es';
import React, { Suspense, useRef } from 'react';
import { useClickAway } from 'react-use';
import useLockBodyScroll from '@js/utils/useLockBodyScroll.js';
import { useTestResultData } from '@js/api';
import renderDataComponent from '@components/TrialMaps/renderDataComponent';

import { ReactComponent as IconClose } from '@icons/close.svg';
import { ReactComponent as LoaderIcon } from '@icons/loader.svg';

export default function MapOverlay({
  id,
  type,
  product,
  handleClose,
  mainColor,
  category,
  featureAvIds
}) {
  const testResult = useTestResultData({
    id,
    type,
    variety: product,
    featureAvIds
  });
  const data = head(testResult.data);

  const ref = useRef(null);

  useLockBodyScroll(ref, true);
  useClickAway(ref, () => {
    handleClose();
  });

  return (
    <div
      ref={ref}
      className="pb-12 overflow-x-hidden overflow-y-auto MapOverlay"
    >
      <button
        className="absolute right-9 top-9 MapOverlay-close"
        onClick={handleClose}
      >
        <IconClose className="text-white fill-current" />
      </button>
      <Suspense
        fallback={
          <div className="flex items-center justify-center h-full">
            <LoaderIcon className="w-12 h-12 text-black fill-current" />
          </div>
        }
      >
        {renderDataComponent(data, mainColor, category)}
      </Suspense>
    </div>
  );
}
