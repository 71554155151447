import React from 'react';
import { useTheliaBlock } from '@js/api';

export default function DataIndependent({ data, mainColor }) {
  const block = useTheliaBlock(data?.data?.thelia_block, { renderHTML: true });

  if (!block?.data?.response) return null;

  return (
    <div>
      <div className={`text-4xl MapOverlay-header pb-12 ${mainColor}`}>
        {block.data?.response?.title}
      </div>
      <div
        className="MapOverlay-content wysiwyg"
        dangerouslySetInnerHTML={{ __html: block?.data?.html }}
      />
    </div>
  );
}
