import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock';

import { useEffect } from 'react';

export default function useLockBodyScroll(ref, active) {
  useEffect(() => {
    try {
      if (ref.current && active) {
        disableBodyScroll(ref.current);
      } else if (ref.current && !active) {
        enableBodyScroll(ref);
      }
    } catch (error) {
      console.error('error: ', error);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [ref, active]);
}
