import React from 'react';
import { Overlay } from '@components/OverlayContact/OverlayContact.js';
import { ReactComponent as PhoneIcon } from '@icons/phone.svg';

export default function Salesman({ salesman }) {
  return (
    <div className="flex items-center justify-center py-10 border-t">
      <div className="mr-6">
        <img
          src={salesman.image}
          alt=""
          className="object-cover w-20 h-20 rounded-full"
        />
      </div>
      <div className="mr-8 ">
        <div className="font-semibold">
          {salesman.firstname} {salesman.lastname}
        </div>
        <div className="text-gray-500 text-xxs">Responsable de secteur</div>
      </div>
      <div>
        <button
          data-salesman-id={`${salesman.id}`}
          onClick={(e) => {
            Overlay.toggle(e);
          }}
          className="flex btn btn--secondary left"
        >
          <span className="icon-left">
            <PhoneIcon />
          </span>
          <span>Contacter</span>
        </button>
      </div>
    </div>
  );
}
