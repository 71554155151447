import GoogleMapReact from 'google-map-react';
import dissolve from 'geojson-dissolve';
import style from './mapStyle.js';
import React from 'react';
import { ReactComponent as IconClose } from '@icons/close.svg';
import Marker from './Marker.js';

export const defaultProps = {
  center: {
    lat: 59.95,
    lng: 30.33
  },
  zoom: 8,
  category: null,
  product: null,
  mainColor: 'bg-red-600'
};

export default function MapDisplay({
  styleType,
  type,
  results,
  season,
  isSuccess,
  center,
  zoom,
  handleClick
}) {
  const [forceRender, setForceRender] = React.useState(Date.now());
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    setForceRender(Date.now());
  }, [season]);

  if (!results) return null;

  const markers = results.filter((item) => {
    return item?.position?.type === 'point';
  });

  const trials = results.filter((item) => {
    return item.position?.type === 'area';
  });

  return (
    <div key={forceRender}>
      <div className="Map-preview">
        <img
          src={window.PLACEHOLDER_MAP}
          alt="Visuel placeholder map"
          title="Placeholder map"
          className="Map-previewMap"
        />

        <button
          className="btn btn--rounded btn--secondaryInverted"
          onClick={() => setIsOpen((open) => !open)}
        >
          Explorer la carte
        </button>
      </div>
      <div className={`Map ${isOpen ? 'is-open' : ''}`}>
        <button
          className="Map-closeIcon js-closeOverlayContact"
          data-state="close"
          onClick={() => setIsOpen((open) => !open)}
          aria-label="Quitter la carte"
        >
          <IconClose className="w-6 h-6 fill-current" />
        </button>

        <div className="flex gap-6 Map-labels">
          {type === 'testimony' && styleType === 'hdi' ? (
            <>
              <div className="flex items-center gap-3 text-xs font-semibold">
                <div className="Map-marker Map-marker--red"></div>
                Parole d'éleveurs
              </div>
              <div className="flex items-center gap-3 text-xs font-semibold">
                <div className="Map-marker Map-marker--dark"></div>
                Comparatif résultats maïs HDi® / non-HDi®
              </div>{' '}
            </>
          ) : null}
          {type === 'testimony' && styleType !== 'hdi' ? (
            <>
              <div className="flex items-center gap-3 text-xs font-semibold">
                <div className="Map-marker Map-marker--red"></div>
                Témoignage
              </div>
            </>
          ) : null}

          {type === 'lg' && markers.length > 0 ? (
            <>
              <div className="flex items-center gap-3 text-xs font-semibold">
                <div className="Map-marker Map-marker--red"></div>
                Résultats LG
              </div>
            </>
          ) : null}
          {type === 'independent' && markers.length > 0 ? (
            <>
              <div className="flex items-center gap-3 text-xs font-semibold">
                <div className="Map-marker Map-marker--gray"></div>
                Résultats indépendants
              </div>
            </>
          ) : null}
        </div>
        {isSuccess && results ? (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: window.GOOGLE_MAP_KEY
            }}
            key={type}
            defaultCenter={center || defaultProps.center}
            defaultZoom={zoom || defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals
            options={{
              fullscreenControl: window.innerWidth >= 1024
            }}
            onGoogleApiLoaded={({ map, maps }) => {
              // define and initialize custom map style (https://mapstyle.withgoogle.com/)
              const styledMapType = new maps.StyledMapType(style, {
                name: 'Limagrain Map Style'
              });
              map.mapTypes.set('Limagrain Map Style', styledMapType);
              map.setMapTypeId('Limagrain Map Style');

              const bounds = new maps.LatLngBounds();

              for (const trial of trials) {
                const areas = trial.position.data || [];

                try {
                  map.data.addGeoJson({
                    type: 'FeatureCollection',
                    features: [
                      {
                        type: 'Feature',
                        geometry: dissolve(areas),
                        properties: {
                          id: trial.id,
                          type: trial.type
                        }
                      }
                    ]
                  });
                } catch (error) {}
              }

              for (const item of markers) {
                bounds.extend({
                  lat: item.position.data[0],
                  lng: item.position.data[1]
                });
              }

              map.data.setStyle({
                fillOpacity: 0.2,
                fillColor: 'rgba(39, 37, 38, 1)',
                strokeColor: 'rgba(39, 37, 38, 1)',
                strokeWeight: 0.5
              });

              map.data.forEach(function (feature) {
                map.data.overrideStyle(feature, {
                  fillColor: feature.getProperty('color')
                });
                feature.getGeometry().forEachLatLng(function (latlng) {
                  bounds.extend(latlng);
                });
              });

              map.data.addListener('click', (e) => {
                handleClick(
                  e.feature.getProperty('id'),
                  e.feature.getProperty('type')
                );
              });

              map.data.addListener('mouseover', function (e) {
                this.overrideStyle(e.feature, {
                  fillOpacity: 0.35
                });
              });

              map.data.addListener('mouseout', function (e) {
                this.overrideStyle(e.feature, {
                  fillOpacity: 0.2
                });
              });

              map.fitBounds(bounds);
            }}
            onChildClick={(key, child) => {
              handleClick(child.id, child.variant);
            }}
          >
            {markers.map((item) => {
              const [lat, lng] = item?.position?.data;
              return (
                <Marker
                  key={`${item.type}-${item.id}`}
                  id={item.id}
                  variant={item.type}
                  lat={lat}
                  lng={lng}
                />
              );
            })}
          </GoogleMapReact>
        ) : null}
      </div>
    </div>
  );
}
