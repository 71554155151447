import React, { useEffect, useMemo } from 'react';

import Tabs from './Tabs';

export default function DataIntern({ trial, mainColor, category }) {
  const [currentDate, setCurrentDate] = React.useState('');
  const [activeProductTitle, setActiveProductTitle] = React.useState('');

  const productList = useMemo(() => {
    return trial?.data?.products || [];
  }, [trial]);

  const activeProductsTitles = useMemo(() => {
    const titles = productList.map((p) => p.product.title);

    return [...new Set(titles)];
  }, [productList]);

  const activeDates = useMemo(() => {
    return productList
      .filter((p) => p.date_label && p.product.title === activeProductTitle)
      .map((p) => p.date_label);
  }, [productList, activeProductTitle]);

  useEffect(() => {
    setActiveProductTitle(activeProductsTitles?.[0]);
  }, [activeProductsTitles]);

  let activeProduct = useMemo(() => {
    return productList.find((p) => {
      if (currentDate) {
        return (
          p.product.title === activeProductTitle && p.date_label === currentDate
        );
      }
      return p.product.title === activeProductTitle;
    });
  }, [productList, activeProductTitle, currentDate]);

  useEffect(() => {
    if (activeDates.length > 0) {
      setCurrentDate(activeDates[0]);
    }
  }, [activeDates]);

  const handleChangeActiveProduct = (title) => {
    setActiveProductTitle(title);
  };

  const handleDateChange = (value) => {
    setCurrentDate(value);
  };

  if (!activeProduct) return null;

  let yieldText = 'Rendement net';
  let performanceMeasurementUnit = '(q/ha)';
  let buttonNotice = '';

  // if mais ensilage
  if (category === '1') {
    performanceMeasurementUnit = '(tMS/ha)';
  }

  // "si blé, orge ou triticale"
  if (category === '5' || category === '6' || category === '7') {
    yieldText = 'Rendement traité';
    performanceMeasurementUnit = '';
    buttonNotice =
      'Les valeurs de la variété LG sont en écart à la moyenne des essais. La base de référence « moyenne » et le nombre d’essais pris en compte sont indiqués.';
  }

  const ps = {
    default: activeProduct['extra-fields'].filter(
      (extraField) => extraField.field === 'PS_(écart)_LG'
    )[0]?.value,
    moy: activeProduct['extra-fields'].filter(
      (extraField) => extraField.field === 'PS_(kg/hl)_MOYENNE'
    )[0]?.value,
    nbEssais: activeProduct['extra-fields'].filter(
      (extraField) => extraField.field === 'NOMBRE_ESSAI_PS'
    )[0]?.value
  };

  const protein = {
    default: activeProduct['extra-fields'].filter(
      (extraField) => extraField.field === 'TAUX_DE_PROTEINE_(écart)_LG'
    )[0]?.value,
    moy: activeProduct['extra-fields'].filter(
      (extraField) => extraField.field === 'TAUX_DE_PROTEINE_(%)_MOYENNE'
    )[0]?.value,
    nbEssais: activeProduct['extra-fields'].filter(
      (extraField) => extraField.field === 'NOMBRE_ESSAI_TAUX_DE_PROTEINE'
    )[0]?.value
  };

  const caliber = {
    default: activeProduct['extra-fields'].filter(
      (extraField) => extraField.field === 'CALIBRAGE_(écart)_LG'
    )[0]?.value,
    moy: activeProduct['extra-fields'].filter(
      (extraField) => extraField.field === 'CALIBRAGE_(%)_MOYENNE'
    )[0]?.value,
    nbEssais: activeProduct['extra-fields'].filter(
      (extraField) => extraField.field === 'NOMBRE_ESSAI_CALIBRAGE'
    )[0]?.value
  };

  const NOT_CAP = [1, 2, 3, 4];

  const isNotCAP = NOT_CAP.includes(parseInt(category, 10));

  const gridBasedOnCAP = isNotCAP ? 'grid-cols-3' : 'grid-cols-4';

  return (
    <div>
      <section className="-mx-4">
        <section
          className={`flex flex-col w-full pt-16 text-white ${mainColor} MapOverlay-header`}
        >
          <h2 className="flex-1 mb-8 text-2xl text-white">{trial.data.city}</h2>
          <div>
            <Tabs
              activeProductTitle={activeProductTitle}
              setActiveProductTitle={handleChangeActiveProduct}
              productsTitles={activeProductsTitles}
              trialId={trial.id}
              mainColor={mainColor}
            />
          </div>
        </section>
        <div id="TabContent" className="px-8 py-8 lg:px-20">
          {/* select date */}
          {activeDates.length > 0 ? (
            <div>
              <select
                className="mb-10"
                value={currentDate ?? ''}
                onChange={(e) => handleDateChange(e.target.value)}
              >
                {activeDates &&
                  activeDates.map((opt) => {
                    return (
                      <option key={opt} value={opt}>
                        {opt}
                      </option>
                    );
                  })}
              </select>
            </div>
          ) : null}

          <div className="border-b divide-y mb-14">
            <div
              className={`grid ${gridBasedOnCAP} py-3 text-gray-600 text-xxs`}
            >
              <div></div>
              <div className="text-right">Variété LG</div>
              <div className="text-right">Moyenne</div>
              {!isNotCAP ? (
                <div className="text-right">Nombre d'essais</div>
              ) : null}
            </div>

            <div className={`grid ${gridBasedOnCAP} py-3 font-semibold`}>
              <div>
                {yieldText} {performanceMeasurementUnit}
              </div>
              <div className="text-right text-brand-600">
                {activeProduct.efficiency}
              </div>
              <div className="text-right">{activeProduct.efficiency_mean}</div>
              {!isNotCAP ? (
                <div className="text-right">
                  {activeProduct['extra-fields'].filter(
                    (extraField) =>
                      extraField.field === 'NOMBRE_ESSAI_RENDEMENT'
                  )[0]?.value ?? activeProduct.humidity_mean}
                </div>
              ) : null}
            </div>

            {ps.default || ps.moy || ps.nbEssais ? (
              <div className={`grid ${gridBasedOnCAP} py-3 font-semibold`}>
                <div>PS</div>
                <div
                  className={`text-right ${!isNotCAP ? 'text-brand-600' : ''}`}
                >
                  {ps.default}
                </div>
                <div className="text-right">{ps.moy}</div>
                {!isNotCAP ? (
                  <div className="text-right">{ps.nbEssais}</div>
                ) : null}
              </div>
            ) : null}

            {protein.default || protein.moy || protein.nbEssais ? (
              <div className={`grid ${gridBasedOnCAP} py-3 font-semibold`}>
                <div>Taux de protéine</div>
                <div
                  className={`text-right ${!isNotCAP ? 'text-brand-600' : ''}`}
                >
                  {protein.default}
                </div>
                <div className="text-right">{protein.moy}</div>
                {!isNotCAP ? (
                  <div className="text-right">{protein.nbEssais}</div>
                ) : null}
              </div>
            ) : null}

            {caliber.default || caliber.moy || caliber.nbEssais ? (
              <div className={`grid ${gridBasedOnCAP} py-3 font-semibold`}>
                <div>Calibrage à 2,5 mm</div>
                <div
                  className={`text-right ${!isNotCAP ? 'text-brand-600' : ''}`}
                >
                  {caliber.default}
                </div>
                <div className="text-right">{caliber.moy}</div>
                {!isNotCAP ? (
                  <div className="text-right">{caliber.nbEssais}</div>
                ) : null}
              </div>
            ) : null}

            {activeProduct?.humidity ? (
              <div className={`grid ${gridBasedOnCAP} py-3 font-semibold`}>
                <div>Humidité (%H2O)</div>
                <div
                  className={`text-right ${!isNotCAP ? 'text-brand-600' : ''}`}
                >
                  {activeProduct.humidity}
                </div>
                <div className="text-right">
                  {activeProduct['extra-fields'].filter(
                    (extraField) => extraField.field === 'TX_H2O_MOY_ESSAI'
                  )[0]?.value ?? activeProduct.humidity_mean}
                </div>
              </div>
            ) : null}
          </div>

          <div className="font-semibold border-t border-b divide-y">
            {activeProduct.ground_type && (
              <div className="flex justify-between py-3">
                <div>Type de sol :</div>
                <div>{activeProduct.ground_type}</div>
              </div>
            )}

            {activeProduct.test_type && (
              <div className="flex justify-between py-3">
                <div>Type d'essais :</div>
                <div className="text-right">{activeProduct.test_type}</div>
              </div>
            )}

            {activeProduct.date_semis && (
              <div className="flex justify-between py-3">
                <div>Date de semis :</div>
                <div className="text-right">{activeProduct.date_semis}</div>
              </div>
            )}

            {activeProduct.date_recolte && (
              <div className="flex justify-between py-3">
                <div>Date de récolte :</div>
                <div className="text-right">{activeProduct.date_recolte}</div>
              </div>
            )}

            {activeProduct.irrigation && (
              <div className="flex justify-between py-3">
                <div>Disponibilité en eau :</div>
                <div className="text-right">{activeProduct.irrigation}</div>
              </div>
            )}

            {Array.isArray(activeProduct?.['extra-fields']) &&
              activeProduct?.['extra-fields']
                ?.filter(
                  (extraField) =>
                    extraField.field !== 'TX_H2O_MOY_ESSAI' &&
                    extraField.field !== 'ANNEE' &&
                    extraField.field !== 'PS_(écart)_LG' &&
                    extraField.field !== 'NOMBRE_ESSAI_RENDEMENT' &&
                    extraField.field !== 'PS_(kg/hl)_MOYENNE' &&
                    extraField.field !== 'NOMBRE_ESSAI_PS' &&
                    extraField.field !== 'TAUX_DE_PROTEINE_(écart)_LG' &&
                    extraField.field !== 'TAUX_DE_PROTEINE_(%)_MOYENNE' &&
                    extraField.field !== 'NOMBRE_ESSAI_TAUX_DE_PROTEINE' &&
                    extraField.field !== 'CALIBRAGE_(écart)_LG' &&
                    extraField.field !== 'CALIBRAGE_(%)_MOYENNE' &&
                    extraField.field !== 'NOMBRE_ESSAI_CALIBRAGE'
                )
                ?.map((row, index) => {
                  if (!row.value) return null;

                  return (
                    <div
                      className="flex justify-between py-3"
                      key={`${index}-${row.value}`}
                    >
                      <div>{row.field}</div>
                      <div className="text-right">{row.value}</div>
                    </div>
                  );
                })}
          </div>
        </div>
      </section>

      <div className="text-right MapOverlay-content">
        <a
          href={activeProduct.product?.url}
          className="inline-block btn btn--rounded"
          target="_blank"
          rel="noreferrer"
        >
          Voir la fiche produit {activeProduct.product?.title}
        </a>
        {buttonNotice ? (
          <div className="mt-2 text-xs leading-tight text-gray-500">
            {buttonNotice}
          </div>
        ) : null}
      </div>
    </div>
  );
}
